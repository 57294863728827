import { render, staticRenderFns } from "./crowded.vue?vue&type=template&id=b64b395a&scoped=true&"
import script from "./crowded.vue?vue&type=script&lang=js&"
export * from "./crowded.vue?vue&type=script&lang=js&"
import style0 from "./crowded.vue?vue&type=style&index=0&id=b64b395a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b64b395a",
  null
  
)

export default component.exports